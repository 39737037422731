import React, { useState, useEffect } from "react";
import Header from "../../COMPONENTS/Header/Header";
import { NavbarDefault } from "../../COMPONENTS/Navbar/Navbar";
import backActivite from "../../ASSETS/Image/backActivity.png";
import activite1 from "../../ASSETS/Image/Activity1.png";
// import activite2 from "../../ASSETS/Image/Activity2.png";
// import activite3 from "../../ASSETS/Image/Activity3.png";
import { Link } from "react-router-dom";
import { Donation } from "./Donation";
import Footer from "../../COMPONENTS/Footer/Footer";
import Loader from "../../COMPONENTS/Loader/Loading";
import { fetchActivityInfo } from '../../API/activity/Activity';
import { fetchActivityBanner } from '../../API/activity/Activity';
import { fetchNouvelles } from '../../API/activity/Activity';
// import { useTranslation } from 'react-i18next';
import Negotiations2025 from './Negotiations2025';
import UnivCercle from './UnivCercle';
import { removeTags } from '../../UTILS/Util';
import axios from "axios"; // Assurez-vous d'importer axios
import { downloadItem } from '../../API/telecharge/Telecharger';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';





function Activite() {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});
  const [Banner, setBanner] = useState('');
  const [BannerPicture, setBannerPicture] = useState('');
  const [Articles, setArticles] = useState([]);
  const [error, setError] = useState(null); // Définir l'état pour l'erreur
  const [Article, setArticle] = useState();
  // const { t } = useTranslation();
  // const { i18n } = useTranslation();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [downloadedItems, setDownloadedItems] = useState(new Set()); // Store downloaded item IDs

    // Fonction pour gérer le téléchargement du fichier
    const handleDownload = async (itemNumber) => {
      setLoading(true);
      setError(null);
    
      try {
        // Appeler la fonction pour télécharger le fichier depuis l'API
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/items/${itemNumber}/download`, {
          responseType: "blob", // Pour gérer les fichiers binaires
        });
    
        // Vérifier si le fichier est un ZIP ou un fichier unique
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'document.pdf'; // Valeur par défaut si aucune valeur trouvée
    
        // Extraire le nom du fichier depuis l'en-tête Content-Disposition
        if (contentDisposition && contentDisposition.includes('attachment')) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches[1]) {
            fileName = matches[1]; // Récupérer le nom du fichier depuis l'en-tête
          }
        }
    
        // Créer un URL pour le fichier téléchargé
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Créer un lien pour télécharger le fichier
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Utiliser le nom dynamique du fichier
        document.body.appendChild(link);
        link.click(); // Simuler un clic pour télécharger
    
        setLoading(false); // Fin du téléchargement
      } catch (err) {
        console.error("Erreur lors du téléchargement :", err.response?.data || err.message);
        setError(err.response?.data?.error || "Erreur lors du téléchargement");
        setLoading(false); // Fin du téléchargement
      }
    };
    
    
    
    
    
    
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1000 milliseconds = 1 second

    return () => clearTimeout(timer); // Cleanup timer if the component unmounts
  }, []);

  // First useEffect to fetch data
  useEffect(() => {
    fetchActivityInfo()
      .then(response => {
        setInfo(response.data.info);
      })
      .catch(error => {
        console.error('Il y avait une erreur!', error);
      });
  
      fetchActivityBanner()
      .then(response => {
        console.log('Réponse de l\'API :', response); // Ajoutez ceci pour vérifier la structure de la réponse
        if (response.data && response.data.info) {
          setBanner(response.data.info); // Stockez directement la bannière
          setBannerPicture(response.data.info); // La valeur de la bannière encodée
        } else {
          console.error('Données de bannière manquantes dans la réponse.');
          setBanner({ fr_text1: 'Des interventions qui font la différence' });
          setBannerPicture('');
        }
      })
      .catch(error => {
        console.error('Il y avait une erreur!', error);
        setBanner({ fr_text1: 'Des interventions qui font la différence' });
        setBannerPicture('');
      });
    
  
    fetchNouvelles()
      .then(response => {
        setArticles(response.data.info);
        setArticle(response.data.categoryDescription);
      })
      .catch(error => {
        console.error('Il y avait une erreur!', error);
      });
  }, []); // Empty dependency array, runs once when the component mounts
  
  // **Ajoutez le nouveau useEffect ici :**
  useEffect(() => {
    console.log('Valeur actuelle de Banner:', Banner);
  }, [Banner]); // Surveillez les changements de l'état `Banner`
  


  return (
    <>

      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loader />
      ) : (
        <>
          <div className="overflow-hidden">
            <Header info={info} />
           <NavbarDefault info={info} /> 

            {/* en tete */}
            
            <div
  className="bg-cover bg-center bg-no-repeat h-[400px] animate-fade animate-once animate-duration-[1000ms] animate-delay-[1ms] animate-ease-linear animate-normal flex justify-center items-center"
  style={{
    backgroundImage: `url(data:image/png;base64,${BannerPicture ? BannerPicture : backActivite})`,
  }}
>
  <div className="bg-[#066AB225] w-full h-full flex justify-center items-center">
    <div
      className="sm:text-3xl md:text-4xl lg:text-4xl md:px-10 px-4 text-2xl font-bold text-white uppercase leading-relaxed animate-fade-up animate-once animate-duration-1000 animate-delay-[1ms] animate-normal text-center sm:max-w-[80%] max-w-[90%] whitespace-normal"
    >
      {Banner && Banner.fr_text1
        ? Banner.fr_text1
        : "Des interventions qui font la différence"}
    </div>
  </div>
</div>

            {/* Activité */}
<div className="py-20 flex flex-col items-center space-y-4">
  <div className="Animation-option uppercase text-lg md:text-2xl w-full sm:w-[400px] text-center font-semibold text-[#4E4E4E]">
    Nos différentes activités
  </div>
  <div className="Animation-option grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 pt-14">
    {Articles.map((article, index) => (
      <div className="activite-img-taille" key={index}>
        <div
          className="rounded-lg img-activite w-full h-auto relative flex items-center justify-center"
          style={{
            backgroundImage: article.Pictures
              ? `url(data:image/png;base64,${article.Pictures})`
              : `url(${activite1})`,
          }}
        >
          {/* Texte centré */}
          <div className="bg-color-activite text-white p-4 text-center absolute inset-0 flex flex-col items-center justify-center">
            <div className="text-xl font-semibold">
              {article.fr_description ? removeTags(article.fr_description) : "Pourquoi nous rejoindre"}
            </div>
          </div>

          {/* Actions */}
          <div className="absolute bottom-4 flex justify-between items-center w-full px-4">
          {/* Bouton Télécharger */}
          <button
            onClick={() => handleDownload(article.Items_Numbers)}
            className="flex items-center space-x-2 bg-blue-500 text-white hover:bg-blue-700 py-2 px-4 rounded-full"
          >
            <ArrowDownTrayIcon className="h-5 w-5" />

            <span>Télécharger</span>
          </button>

          {/* Bouton Lire Plus */}
          <Link
            to={`/Nos-activites/Voir-plus/${article.Items_Numbers}`}
            className="flex flex-row items-center justify-center space-x-3 bg-[#121212] rounded-full py-2 px-4 text-white"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9094 7.11979C13.4971 4.36432 10.7013 2.5 7.49999 2.5C4.29868 2.5 1.50207 4.36563 0.0906108 7.12005C0.0310383 7.23789 0 7.36809 0 7.50013C0 7.63217 0.0310383 7.76237 0.0906108 7.88021C1.50285 10.6357 4.29868 12.5 7.49999 12.5C10.7013 12.5 13.4979 10.6344 14.9094 7.87995C14.9689 7.76211 15 7.63191 15 7.49987C15 7.36783 14.9689 7.23763 14.9094 7.11979ZM7.49999 11.25C6.75831 11.25 6.03328 11.0301 5.4166 10.618C4.79991 10.206 4.31927 9.62029 4.03544 8.93506C3.75161 8.24984 3.67735 7.49584 3.82204 6.76841C3.96674 6.04098 4.32389 5.3728 4.84834 4.84835C5.37278 4.3239 6.04097 3.96675 6.7684 3.82206C7.49583 3.67736 8.24983 3.75162 8.93505 4.03545C9.62027 4.31928 10.2059 4.79993 10.618 5.41661C11.0301 6.0333 11.25 6.75832 11.25 7.5C11.2502 7.99252 11.1534 8.48027 10.965 8.93535C10.7767 9.39043 10.5004 9.80392 10.1522 10.1522C9.8039 10.5005 9.39041 10.7767 8.93533 10.965C8.48025 11.1534 7.99251 11.2502 7.49999 11.25ZM7.49999 5C7.27684 5.00312 7.05514 5.03632 6.84087 5.0987C7.01749 5.33872 7.10225 5.63408 7.07977 5.93124C7.05729 6.22839 6.92906 6.50764 6.71834 6.71836C6.50763 6.92908 6.22837 7.0573 5.93122 7.07978C5.63407 7.10226 5.3387 7.01751 5.09868 6.84089C4.96201 7.34442 4.98668 7.87814 5.16923 8.36693C5.35177 8.85571 5.683 9.27494 6.11629 9.56561C6.54958 9.85629 7.06311 10.0038 7.58461 9.98729C8.10611 9.97082 8.60931 9.79122 9.02339 9.47378C9.43747 9.15634 9.74158 8.71703 9.89291 8.2177C10.0442 7.71838 10.0352 7.18416 9.86699 6.69025C9.69881 6.19635 9.37997 5.76761 8.95536 5.4644C8.53075 5.16119 8.02174 4.99877 7.49999 5Z"
                fill="currentColor"
              />
            </svg>
            <p>{("Lire plus")}</p>
          </Link>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

            



            <Negotiations2025 />

            <UnivCercle />

            {/* Donation */}
            <div className=" Animation-option flex flex-col items-center space-y-4 ">
              <div className="uppercase text-lg md:text-2xl w-[400px] text-center font-semibold text-[#4E4E4E]">
                {/* {t('faire une donation ici')} */}
              </div>
              <div className="sm:w-[800px] text-center pb-8">
                {/* {t('Chaque don, petit ou grand, contribue à transformer des vies et à créer un avenir meilleur pour des milliers de personnes. En choisissant de soutenir l\'Association, vous devenez un acteur clé de notre mission.')} */}
              </div>
              <Donation />
            </div>

            <div className="pt-20">
              <Footer info={info} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Activite;