import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent } from '../../COMPONENTS/ui/card';
import { Calendar } from 'lucide-react';
import { fetchMedia } from '../../API/galeries/MediaAPI'; // Import the Axios call

const Negotiations2025 = () => {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const getMedia = async () => {
      try {
        const mediaData = await fetchMedia();
        setMedia(mediaData);
        console.log(mediaData);
      } catch (error) {
        console.error("Failed to load media", error);
        setError("Failed to load media"); // Set error message
      } finally {
        setLoading(false); // Set loading to false once finished
      }
    };

    getMedia();
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-5 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-center text-[#4E4E4E] p-5">Négociations 2025</h1>
      
      <Card className="mb-8 shadow-lg hover:shadow-xl transition-shadow duration-300 p-5">
        <CardHeader className="bg-[#6F0C11] text-white">
          <h2 className="text-2xl font-semibold flex items-center">
            <Calendar className="mr-2" /> Ligne de Temps des Négociations
          </h2>
        </CardHeader>
        <CardContent className="mt-4">
          <p className="text-gray-700">Voici la ligne de temps des négociations pour l'année 2025 :</p>
          <ul className="list-disc list-inside mt-4">
            <li><strong>Janvier 2025 :</strong> Début des négociations.</li>
            <li><strong>Février 2025 :</strong> Réunion initiale avec les parties prenantes.</li>
            <li><strong>Mars 2025 :</strong> Présentation des propositions.</li>
            <li><strong>Avril 2025 :</strong> Discussions et révisions des propositions.</li>
            <li><strong>Mai 2025 :</strong> Accord finalisé.</li>
          </ul>
        </CardContent>
      </Card>

      {/* <Card className="mt-8 shadow-lg p-5">
        <CardHeader className="bg-[#6F0C11] text-white">
          <h2 className="text-2xl font-semibold">Documents et Médias</h2>
        </CardHeader>
        <CardContent className="mt-4">
  {loading ? (
    <p className="text-gray-700">Chargement des médias...</p> 
  ) : error ? (
    <p className="text-[#6F0C11]">{error}</p> 
  ) : (
    <>
      <p className="text-gray-700">Documents importants, photos et vidéos liés aux négociations :</p>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {media.map((item, index) => (
          <div key={index} className="media-item shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105">
            {item.type === 'photo' ? (
              <img
                src={`data:image/jpeg;base64,${item.file}`}
                alt="Negotiation media"
                className="w-full h-48 object-cover" 
              />
            ) : (
              <video controls className="w-full h-48 object-cover"> // Adjust video size
                <source src={`data:video/mp4;base64,${item.file}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800">{item.type === 'photo' ? 'Photo' : 'Vidéo'}</h3>
            </div>
          </div>
        ))}
      </div>
    </>
  )}
        </CardContent>

      </Card> */}
    </div>
  );
};

export default Negotiations2025;
