import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
// import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { removeTags } from '../../UTILS/Util';
export function Mission(props) {
  // const { i18n } = useTranslation();
  // const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  useEffect(() => {
    // const savedLanguage = localStorage.getItem('language');
    // const browserLang = savedLanguage || navigator.language || navigator.userLanguage;
    // const lang = browserLang.substr(0, 2);
    // i18n.changeLanguage(lang);
    // setCurrentLanguage(lang);
    // console.log('Langue actuelle :', lang);

     // Ajoutez cet écouteur d'événements pour mettre à jour currentLanguage chaque fois que la langue change
    // i18n.on('languageChanged', lng => {
    //   setCurrentLanguage(lng);
    // });

    // N'oubliez pas de nettoyer l'écouteur d'événements lorsque le composant est démonté
    // return () => {
    //   i18n.off('languageChanged');
    // };
  }, []);
  // const { t} = useTranslation();
  const data = [
    {
      label: "Notre histoire", // Le label en français
      value: "html",
      desc: props.info.site_history_fr ? removeTags(props.info.site_history_fr) : "It really matters and then like it really doesn't matter." // La description en français
    },
    {
      label: "Nos valeurs", // Le label en français
      value: "react",
      desc: props.info.site_value_fr ? removeTags(props.info.site_value_fr) : "sa valeur fr" // La description en français
    },
    {
      label: "Notre mission", // Le label en français
      value: "vue",
      desc: props.info.site_mission_fr ? removeTags(props.info.site_mission_fr) : "sa mission" // La description en français
    },
    {
      label: "Notre vision", // Le label en français
      value: "angular",
      desc: props.info.site_vision_fr ? removeTags(props.info.site_vision_fr) : "leur vision" // La description en français
    },
  ];
  
 
  return (
    <Tabs  value="html" className="max-w-[40rem] bg-[#DDF1FF] rounded p-3">
      <TabsHeader
        className="bg-transparent"
        indicatorProps={{
          className: "bg-gray-900/10 shadow-none !text-gray-900",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}